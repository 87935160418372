import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers([
  "card",
  "heading",
  "subHeading",
  "logo",
]);

const WelcomeBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      background: "welcomeBanner.background",
      color: "welcomeBanner.text",
      position: "relative",
      borderRadius: "base",
      px: { base: 8, xl: 12 },
      width: "100%",
      justifyContent: { base: "right", lg: "center" },
      alignItems: "center",
      minHeight: { base: "150px", md: "180px", xl: "200px" },
      display: "flex",
    },
    heading: {
      fontSize: { base: "lg", md: "lg", lg: "xl", xl: "3xl" },
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      px: { base: 8, xl: 12, "2xl": 14 },
      flex: "1",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: "medium",
      textAlign: "start",
      maxWidth: { base: "60%", lg: "40%" },
    },
    subHeading: {
      display: "block",
      fontSize: { base: "xs", md: "sm", lg: "sm", xl: "lg" },
      mb: { md: 1, lg: 1, xl: 2 },
      fontWeight: "normal",
    },
    logo: {
      alignItems: "center",
      justifyContent: { base: "flex-end", lg: "center" },
      svg: {
        width: { base: "150px", md: "150px", lg:"200px", xl: "250px" },
      },
    },
  },
});

export default WelcomeBanner;
