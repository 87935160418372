import { LoggedInUserSession } from "apollo/api/login";
import { deleteCookie, setCookie } from "cookies-next";
import { TmpCookiesObj } from "cookies-next/lib/types";
import { IncomingMessage, ServerResponse } from "http";
import { jwtVerify, SignJWT } from "jose";
import { NextApiRequest, NextApiResponse } from "next";
import { NextRequest, NextResponse } from "next/server";

const jwtSecret = process.env.JWT_SECRET ?? "secret";
const maxAge = Number(60 * 1000);
const alg = "HS256";

export const signJWT = async (payload: LoggedInUserSession) => {
  const secret = new TextEncoder().encode(jwtSecret);
  return new SignJWT(payload)
    .setProtectedHeader({ alg })
    .setExpirationTime(payload.expires)
    .setIssuedAt()
    .setSubject(payload.sub)
    .sign(secret);
};

export const verifyJWT = async (
  token: string | undefined,
  req?: NextRequest,
  res?: NextResponse
): Promise<LoggedInUserSession | undefined> => {
  if (!token) {
    return undefined;
  }
  const secret = new TextEncoder().encode(jwtSecret);

  try {
    const { payload } = await jwtVerify<LoggedInUserSession>(token, secret);
    return payload;
  } catch (e) {
    console.error("JWT verification failed");
    if (req && res) {
      deleteCookie("session", { req, res });
    }
    return undefined;
  }
};

export const setJwtCookie = async ({
  req,
  res,
  session,
}: {
  req: NextApiRequest;
  res: NextApiResponse;
  session: LoggedInUserSession;
}) => {
  const jwt = await signJWT(session);
  setCookie("session", jwt, {
    maxAge: maxAge,
    req,
    res,
    secure: process.env.NODE_ENV === "production",
    sameSite: "strict",
    httpOnly: true,
  });
};
