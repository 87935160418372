import { gql, useQuery } from "@apollo/client";
import PiwikProProvider from "@piwikpro/next-piwik-pro";
import getApolloClient from "apollo/client";
import { GetPiwikProConfigQuery } from "apollo/generatedTypes";
import { Fragment } from "react";

const apolloClient = getApolloClient();

export const GET_PIWIK_PRO_CONFIG = gql`
  query getPiwikProConfig {
    getPiwikProConfig {
      containerId
      containerUrl
    }
    getUserProfile {
      isImpersonated
    }
  }
`;

export const PiwikProContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data } = useQuery<GetPiwikProConfigQuery>(GET_PIWIK_PRO_CONFIG, {
    client: apolloClient,
  });

  const isImpersonated = data?.getUserProfile?.isImpersonated;
  const containerId = data?.getPiwikProConfig?.containerId;
  const containerUrl = data?.getPiwikProConfig?.containerUrl;

  return !isImpersonated && containerId && containerUrl ? (
    <PiwikProProvider containerId={containerId} containerUrl={containerUrl}>
      {children}
    </PiwikProProvider>
  ) : (
    <Fragment>{children}</Fragment>
  );
};
