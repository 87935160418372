import { extendTheme } from "@chakra-ui/react";
import baseTheme from "./theme";
import { desaturate, lighten, linearGradient } from "polished";
import { merge } from "lodash-es";
import { ThemeName } from "apollo/generatedTypes";

const palette = {
  defaultBlack: "#1B1B1B",
  defaultWhite: "#fff",
  defaultLightBlue: "#DFE9EE",
  defaultOrange: "#F07E2660",
  defaultViolet: "#D6C8E060",

  lightBlue: {
    950: "#080e11",
    900: "#111c22",
    800: "#223744",
    700: "#325367",
    600: "#436e89",
    500: "#548aab",
    400: "#76a1bc",
    300: "#98b9cd",
    200: "#bbd0dd",
    100: "#dde8ee",
    50: "#eef3f7",
  },

  deepBlue: {
    950: "#090f11",
    900: "#111d22",
    800: "#223a44",
    700: "#335766",
    600: "#447588",
    500: "#5592aa",
    400: "#77a8bb",
    300: "#99bdcc",
    200: "#bbd3dd",
    100: "#dde9ee",
    50: "#eef4f6",
  },

  blue: {
    900: "#0c1931", // eclipse
    800: "#012858", // midnight
    700: "#06458A", // dawn
    600: desaturate(0.2, lighten(0.1, "#06458A")),
    500: desaturate(0.3, lighten(0.15, "#06458A")),
    400: "#4677B0", // steel
    300: "#749AC8", // iron
    200: "#9AC1F0", // mist
    100: "#CBE1FB", // cloud
    50: "#E6F5FD", //
  },

  green: {
    900: "#0c1931", // eclipse
    800: "#013958", // spruce
    700: "#06628A", // oak
    600: desaturate(0.2, lighten(0.1, "#06628A")),
    500: desaturate(0.3, lighten(0.15, "#06628A")),
    400: "#5D93AB", // pine
    300: "#8EB9C3", // haze
    200: "#B6D9D5", // spring
    100: "#DCEEE8", // dew
    50: "#F5F9F7", //
  },
};

const colors = merge({}, baseTheme.colors, palette);

const theme = extendTheme(baseTheme, {
  config: {
    ...baseTheme.config,
    themeName: ThemeName.Allshares,
  },
  radii: {
    base: "6px",
  },

  semanticTokens: {
    space: {
      gutter: {
        default: 4,
      },
    },
    colors: {
      text: colors.blue[900],
      primary: {
        base: { default: palette.blue[700] },
        subtle: { default: palette.blue[400] },
        inverted: { default: "white" },
        gradient: {
          default: linearGradient({
            colorStops: [colors.blue[800], colors.blue[700], colors.blue[400]],
            toDirection: "to right",
          }).backgroundImage,
        },

        color: "text",
        headerColor: "white",
      },

      secondary: {
        background: "white",
        color: "text",
        headerBackground: "#DFEDFD",
      },

      graph: {
        background: "white",
        color: "black",
        headerBackground: "white",
      },

      neutral: {
        background: "white",
        color: "black",
        headerColor: "text",
        headerBackground: "#DFEDFD",
      },
      timeline: {
        background: "white",
        color: "black",
        headerBackground: "#DFEDFD",
      },

      alert: {
        base: { default: colors.red[600] },
        subtle: { default: colors.red[200] },
        inverted: { default: "white" },
        // Used with eai card
        background: "white",
        headerColor: "white",
        headerBackground: "primary.base",
      },

      success: {
        background: "white",
        headerBackground: "primary.base",
        color: "primary.base",
        headerColor: "primary.inverted",
        inverted: colors.green[100],
      },

      pending: {
        ...colors.red,
        base: { default: colors.red[500] },
        subtle: { default: colors.red[100] },
        inverted: { default: "white" },
      },

      outline: {
        border: "1px",
        borderColor: colors["primary"]["dark"],
        color: colors["blue"][800],
        _hover: {
          background: "transparent",
        },
        _active: {
          color: colors["blue"][600],
        },
      },
      welcomeBanner: {
        background: "primary.gradient",
        text: "white",
      },

      footer: {
        background: colors["blue"][800],
        text: colors["white"],
        link: colors["blue"][100],
        linkHover: colors["blue"][200],
      },

      postLogin: {
        orangeCircle: colors["red"][600],
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "lg",
        textTransform: "none",
      },
      variants: {
        solidBlue: {
          bg: palette.blue[800],
          borderColor: palette.blue[800],
          _hover: {
            bg: palette.blue[700],
            borderColor: palette.blue[700],
          },
        },
      },
    },
    EaiCard: {
      variants: {
        neutral: {
          header: {
            borderColor: "#D1E0F1",
            borderWidth: "1px",
          },
        },
        timeline: {
          header: {
            borderColor: "#D1E0F1",
            borderWidth: "1px",
          },
        },
      },
    },

    Sidebar: {
      baseStyle: {
        toggleButton: {
          background: "white",
          color: "#AAAAAA",
          fontSize: "xl",
          boxShadow: "md",
          _hover: {
            color: "gray.700",
            background: "white",
          },
        },
        navItem: {
          textTransform: "none",
          "&[aria-current=true]": {
            background: "primary.subtle",
            fontWeight: "medium",
            color: "primary.inverted",
          },
        },
        subNavItem: {
          "&[aria-current=true]": {
            background: "primary.subtle",
            fontWeight: "medium",
            color: "primary.inverted",
          },
        },
        icon: {
          "[aria-current=true] &": {
            color: "primary.inverted",
          },
        },
      },
    },
  },

  shadows: {
    smTop: "0px -2px 5px 1px rgba(0,0,0,0.14)",
  },

  fonts: {
    heading: "GeneralSans",
    body: "GeneralSans",
  },
  // Temp fix until hard coded weights are removed
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 500,
    bold: 500,
    500: 400,
    700: 400,
    900: 600,
  },
  colors: {
    ...colors,
    app: {
      background: "#F5F7F9",
    },
    login: {
      background: "primary.gradient",
      helpdeskBackground: "#08375C",
      helpdeskColor: "white",
      helpdeskDisclaimerBackground: "transparent",
    },
    donutGraph: [
      palette["blue"][800],
      palette["blue"][600],
      palette["blue"][300],
      palette["blue"][200],
      palette["blue"][50],
      palette["defaultWhite"],
      "#E6EDF1",
    ],
    timelinePeriodColors: {
      // Short term (common)
      sharePurchasesEssp: palette["blue"][500],
      sharePurchases: palette["blue"][400],
      // Short term (rare)
      boardSetsEarningCriteria: palette["green"][500],
      dividendAdjustment: palette["blue"][50],
      measureOfAchievement: palette["blue"][200],
      shareDelivery: palette["green"][200],
      vestingPeriodRequirement: palette["blue"][300],
      optionExpirationDate: palette["blue"][800],
      optionDelivery: palette["green"][600],
      // Long term
      optionVestingPeriod: palette["blue"][600],
      ownershipRequirement: palette["blue"][500],
      performancePeriod: palette["blue"][700],
      savings: palette["green"][300],
      subscriptionPeriod: palette["blue"][400],
    },

    personnelFundGraphColors: {
      restricted: colors["blue"][800],
      withdrawable: colors["blue"][500],
      toBePaid: colors["blue"][300],
      newInvestments: colors["blue"][200],
      newTransfersWithdrawable: colors["blue"][100],
      marketValue: colors["blue"][700],
    },

    navbar: {
      background: colors["lightBlue"][200],
      color: colors["lightBlue"][950],
    },

    footer: {
      background: colors["lightBlue"][900],
      color: "primary.color",
    },

    brand: {
      highlight: colors["pink"][500],
      selected: colors["pink"][600],
      color: "black",
      background: colors["defaultWhite"],
      secondaryBackground: colors["blue"][500],
      tertiaryBackground: colors["defaultOrange"],

      heading1: colors["deepBlue"][700],
    },

    input: {
      background: baseTheme.colors["white"],
      border: colors["gray"][200],
      color: "black",
      hoverBackground: colors["gray"][50],
    },
    link: colors["blue"][800],

    navigationButton: {
      color: "white",
      background: colors["blue"][800],
      hoverBackground: colors["blue"][700],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: colors["blue"][900],
      },
    },
    inActiveNavigationButton: {
      color: "black",
      background: colors["deepBlue"][100],
      hoverBackground: colors["deepBlue"][300],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: colors["deepBlue"][200],
      textTransform: "none",
      _active: {
        bg: colors["deepBlue"][500],
      },
    },

    table: {
      subHeader: colors["blue"][50],
      evenBackground: colors["gray"][50],
      oddBackground: colors["white"],
      rowBorder: colors["gray"][300],
      footerBorder: colors["blue"][800],
      hover: colors["whiteAlpha"][700],
      header: colors["blue"][300],
      selectedBackground: linearGradient({
        colorStops: [
          colors["gray"][100],
          colors["gray"][50],
          colors["gray"][100],
        ],
        toDirection: "to right",
      }).backgroundImage,
      alertBackground: colors["deepBlue"][100],
      activeBackground: colors["gray"][100],
      summaryBackground: colors["gray"][200],
      summaryColor: colors["black"],
      color: colors["black"],
    },

    multiselect: {
      container: {
        border: "2px solid",
        background: "transparent",
        borderColor: "input.border",
      },
      valueContainer: {
        background: "input.background",
        paddingLeft: 2,
        width: "100%",
        minHeight: "40px",
      },
      control: {
        border: "2px solid",
        borderColor: "input.border",
        borderRadius: 6,
      },
      menu: {
        zIndex: 2,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
        mt: "2px",
      },
      dropdownIndicator: {
        background: "white",
      },
    },
  },
});

export type AllSharesTheme = typeof theme;

export default theme;
